import * as React from "react";
import { MenuItemLink, usePermissions } from "react-admin";
import { useSelector } from "react-redux";

import {
  Dashboard,
  AccountBalance,
  AttachMoney,
  AddCircleOutlined,
  PostAdd,
  LibraryAdd,
  DeviceHub,
  Equalizer,
  CalendarViewDay,
  Favorite,
  DoubleArrow,
  Receipt,
  AccountTree,
  AccountBalanceWallet
} from "@material-ui/icons";

const MyMenu = ({ onMenuClick, logout }) => {
  const { permissions } = usePermissions();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  let parsedPermissions = "";

  if (permissions) {
    parsedPermissions = permissions.split(",");
  }

  const rotinas = {
    dashboard: "Painel de Controle",
    transfers: "Transferências",
    transactions: "Vendas",
    transactionsCreate: "Nova Venda",
    sale_plan: "Planos de Assinatura",
    recurring_purchase: "Compra Recorrente",
    sale_link: "Venda por Link",
    "future/transfer": "Transferências Futuras",
    "payment/boleto": "Pagar Conta",
    donate: "Doação por Link",
    ted: "TED",
    extract: "Extrato",
    seller_plan: "Plano de Taxas",
    bank_account: "Conta Bancária",
  };

  const icons = {
    dashboard: <Dashboard />,
    transfers: <AccountBalance />,
    transactions: <AttachMoney />,
    transactionsCreate: <AddCircleOutlined />,
    sale_plan: <PostAdd />,
    recurring_purchase: <LibraryAdd />,
    sale_link: <DeviceHub />,
    "future/transfer": <Equalizer />,
    "payment/boleto": <CalendarViewDay />,
    donate: <Favorite />,
    ted: <DoubleArrow />,
    extract: <Receipt />,
    seller_plan: <AccountTree />,
    bank_account: <AccountBalanceWallet />,
  };

  return (
    <div>
      {parsedPermissions &&
        parsedPermissions.map((item) => {
          return (
            <MenuItemLink
              key={item}
              to={`/${item === "dashboard" ? "" : item}`}
              leftIcon={icons[item]}
              primaryText={rotinas[item]}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        })}
      {logout}
    </div>
  );
};

export default MyMenu;
